var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header'),_c('c-box',{attrs:{"paddingTop":"30px","paddingBottom":"80px"}},[_c('c-box',{attrs:{"w":"100%","maxWidth":"1200px","mx":"auto"}},[_c('c-breadcrumb',{directives:[{name:"chakra",rawName:"v-chakra",value:({
          ol: {
            li: {
              a: {
                fontSize: '14px',
                fontFamily: 'Roboto',
              },
              span: {
                fontSize: '14px',
                fontFamily: 'Roboto',
              },
            },
          },
        }),expression:"{\n          ol: {\n            li: {\n              a: {\n                fontSize: '14px',\n                fontFamily: 'Roboto',\n              },\n              span: {\n                fontSize: '14px',\n                fontFamily: 'Roboto',\n              },\n            },\n          },\n        }"}],attrs:{"marginTop":"20px","marginBottom":"20px"}},[_c('c-breadcrumb-item',[_c('c-breadcrumb-link',{attrs:{"href":"#","color":"brand.900","fontSize":"14px","fontFammily":"Roboto"}},[_vm._v(" Beranda ")])],1),_c('c-breadcrumb-item',{attrs:{"isCurrentPage":""}},[_c('c-breadcrumb-link',{attrs:{"href":"#","fontSize":"14px !important","fontFammily":"Roboto !important"}},[_vm._v(" Privacy Policy ")])],1)],1),(_vm.item)?_c('c-heading',{attrs:{"as":"h2","fontSize":"36px","fontWeight":"600","color":"black.900","marginBottom":"40px"}},[_vm._v(" "+_vm._s(_vm.item.title)+" ")]):_vm._e(),(_vm.item)?_c('c-box',{attrs:{"borderTop":"1px solid #F2F2F2","paddingTop":"30px"}},[_c('c-text',{attrs:{"fontSize":"16px","lineHeight":"28px","color":"darkGrey.900","marginBottom":"30px"},domProps:{"innerHTML":_vm._s(_vm.item.content)}}),_c('c-accordion',{directives:[{name:"chakra",rawName:"v-chakra",value:({
            '.css-0': {
              ':first-child': {
                borderTop: 'none',
              },
              ':last-child': {
                borderBottom: 'none',
              },
              button: {
                ':focus': {
                  outline: 'none',
                  boxShadow: 'none',
                },
                svg: {
                  path: {
                    fill: '#008C81',
                  },
                },
              },
            },
          }),expression:"{\n            '.css-0': {\n              ':first-child': {\n                borderTop: 'none',\n              },\n              ':last-child': {\n                borderBottom: 'none',\n              },\n              button: {\n                ':focus': {\n                  outline: 'none',\n                  boxShadow: 'none',\n                },\n                svg: {\n                  path: {\n                    fill: '#008C81',\n                  },\n                },\n              },\n            },\n          }"}],attrs:{"allow-multiple":true,"default-index":[0],"boxShadow":"4px 4px 50px 5px #0000000D","borderRadius":"6px"}},_vm._l((_vm.item.privacyPolicy),function(child,idx){return _c('c-accordion-item',{key:idx},[_c('c-accordion-header',[_c('c-box',{attrs:{"flex":"1","text-align":"left","fontWeight":"700","py":"10px","px":"18px","fontFamily":"Roboto","fontSize":"18px","color":"black"},domProps:{"innerHTML":_vm._s(child.subTitle)}}),_c('c-accordion-icon')],1),_c('c-accordion-panel',{attrs:{"px":"36px","pb":"4","borderTop":"1px solid #F2F2F2"}},[_c('c-text',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                  ul: {
                    paddingLeft: '30px',
                    color: '#555555',
                  },
                }),expression:"{\n                  ul: {\n                    paddingLeft: '30px',\n                    color: '#555555',\n                  },\n                }"}],attrs:{"fontFamily":"Roboto","fontSize":"16px","color":"darkGray","marginBottom":"30px","lineHeight":"28px"},domProps:{"innerHTML":_vm._s(child.subContent)}})],1)],1)}),1)],1):_vm._e()],1)],1),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }