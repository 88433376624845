<template>
  <div>
    <Header />
    <c-box paddingTop="30px" paddingBottom="80px">
      <c-box w="100%" maxWidth="1200px" mx="auto">
        <c-breadcrumb
          marginTop="20px"
          marginBottom="20px"
          v-chakra="{
            ol: {
              li: {
                a: {
                  fontSize: '14px',
                  fontFamily: 'Roboto',
                },
                span: {
                  fontSize: '14px',
                  fontFamily: 'Roboto',
                },
              },
            },
          }"
        >
          <c-breadcrumb-item>
            <c-breadcrumb-link
              href="#"
              color="brand.900"
              fontSize="14px"
              fontFammily="Roboto"
            >
              Beranda
            </c-breadcrumb-link>
          </c-breadcrumb-item>

          <c-breadcrumb-item isCurrentPage>
            <c-breadcrumb-link
              href="#"
              fontSize="14px !important"
              fontFammily="Roboto !important"
            >
              Privacy Policy
            </c-breadcrumb-link>
          </c-breadcrumb-item>
        </c-breadcrumb>
        <c-heading v-if="item"
          as="h2"
          fontSize="36px"
          fontWeight="600"
          color="black.900"
          marginBottom="40px"
        >
          {{ item.title }}
        </c-heading>
        <c-box v-if="item" borderTop="1px solid #F2F2F2" paddingTop="30px">
          <c-text
            fontSize="16px"
            lineHeight="28px"
            color="darkGrey.900"
            marginBottom="30px"
            v-html="item.content"
          >
          </c-text>
          <c-accordion
            :allow-multiple="true"
            :default-index="[0]"
            boxShadow="4px 4px 50px 5px #0000000D"
            borderRadius="6px"
            v-chakra="{
              '.css-0': {
                ':first-child': {
                  borderTop: 'none',
                },
                ':last-child': {
                  borderBottom: 'none',
                },
                button: {
                  ':focus': {
                    outline: 'none',
                    boxShadow: 'none',
                  },
                  svg: {
                    path: {
                      fill: '#008C81',
                    },
                  },
                },
              },
            }"
          >
            <c-accordion-item
              v-for="(child, idx) in item.privacyPolicy"
              :key="idx"
            >
              <c-accordion-header>
                <c-box
                  flex="1"
                  text-align="left"
                  fontWeight="700"
                  py="10px"
                  px="18px"
                  fontFamily="Roboto"
                  fontSize="18px"
                  color="black"
                  v-html="child.subTitle"
                >
                </c-box>
                <c-accordion-icon />
              </c-accordion-header>
              <c-accordion-panel px="36px" pb="4" borderTop="1px solid #F2F2F2">
                <c-text
                  fontFamily="Roboto"
                  fontSize="16px"
                  color="darkGray"
                  marginBottom="30px"
                  lineHeight="28px"
                  v-chakra="{
                    ul: {
                      paddingLeft: '30px',
                      color: '#555555',
                    },
                  }"
                  v-html="child.subContent"
                />
              </c-accordion-panel>
            </c-accordion-item>
          </c-accordion>
        </c-box>
      </c-box>
    </c-box>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header/index.vue";
import Footer from "@/components/Footer.vue";
import { mapGetters } from "vuex";

export default {
  name: "PrivacyPolicyPage",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      item: '',
    };
  },
  computed: {
    ...mapGetters({
      axiosCMS: "customAxiosCMS",
    }),
  },
  async mounted() {
    this.loadPrivacyPolicy();
  },
  methods: {
    loadPrivacyPolicy() {
      this.axiosCMS.get("/privacy-and-policy").then((res) => {
        this.item = res.data;
      });
    },
  },
};
</script>

<style>
</style>
